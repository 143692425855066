<template>
	<!-- 查看数据项信息 -->
	<el-dialog title="查看数据项信息" :visible.sync="visible" width="55%">
		<el-card>
			<el-table :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe>
				<el-table-column prop="rowKey" label="时间" header-align="center" align="center" width="300">
				</el-table-column>
				<el-table-column prop="pointnum" label="数据项值" header-align="center" align="center" width="300">
				</el-table-column>
			</el-table>
		</el-card>
		<!-- 分页区域 -->
		 <el-pagination
                        background
                        @current-change="currentChange"
                        @size-change="sizeChange"
                        :current-page="page"
                        :page-size="size"
                        layout="sizes, prev, pager, next, jumper, ->, total, slot"
                        :total="total">
                </el-pagination>

	</el-dialog>
</template>

<script>
	export default {
		name: "checkSystem",
		data() {
			return {
				page: 1, //当前第几页
				size: 10, //当前每页页数
				total: 50, //全部数据量
				visible: false,
				value: "",
				tableData: [{
						rowKey: '3-15',
						pointnum: '32.3'

					},
					// {
					// rowKey:'3-16',
					// num: 32
					// },
					// {
					// rowKey:'3-17',
					// num: 51
					// }
				],

				currentPage1: 1,
				currentPage2: 2,
				currentPage3: 3,
				currentPage4: 4,
				currentpointname: '',
				currentpointId:'',
			};
		},
		methods: {
			currentChange(currentPage){
                this.page=currentPage;
                this.initData();
            },
            sizeChange(currentSize) {
                this.size = currentSize;
                this.initData();
            },
			initData(){
				this.getRequest('/hbase/getPointnumById/?pointId=' + this.currentpointId + '&page=' + this.page + '&size=' + this.size).then(resp => {
					if (resp.data) {
						for (var i = 0; i < this.size; i++) {
						this.tableData = resp.data;
							this.tableData[i].pointnum=JSON.stringify(resp.data[i].properties);
							this.tableData[i].rowKey = resp.data[i].rowKey;
						}
						// this.total = resp.data.size;
					}
				});
			},
			init(row) {
				console.log(row);
				this.currentpointId = row.id;
				this.initData();
				this.visible = true;
			},
		},
	};
</script>


<style scoped>
</style>
